::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 3px rgba(255, 255, 255, 0);
	background-color: rgba(243, 0, 0, 0);
}

::-webkit-scrollbar-track-piece {
	background-color: #ffffff3c;
}
::-webkit-scrollbar {
	width: 8px;
	height: 8px;
	background-color: #3502ff00;
}

::-webkit-scrollbar-thumb {
	background-color: #7e7e7e38;
	border: 0px;
}
